// List of all Live bani banks available
export const bankList = [
  {
    label: "9Payment Service Bank",
    name: "9Payment service bank",
    value: "120001",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/9bank_c0jqji.png",
  },
  {
    label: "Access Bank",
    name: "Access bank",
    value: "000014",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/accessbank_w23utv.png",
  },
  {
    label: "Access Bank (Diamond)",
    name: "Access bank (diamond)",
    value: "000005",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/accessbank_w23utv.png",
  },
  {
    label: "CIT Microfinance Bank",
    name: "CIT Microfinance Bank",
    value: "090144",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/citbank_ey1a4x.png",
  },
  {
    label: "Citibank Nigeria",
    name: "Citibank nigeria",
    value: "000009",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/Citibank_zozj7m.png",
  },
  {
    label: "Ecobank",
    name: "Ecobank",
    value: "000010",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/logo-ecobank_otqxdd.jpg",
  },
  {
    label: "Eyowo",
    name: "Eyowo",
    value: "090328",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/eyowo_bank_ha0alm.png",
  },
  {
    label: "Fidelity Bank",
    name: "Fidelity bank",
    value: "000007",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/fidelitybank_now8zu.png",
  },
  {
    label: "First Bank of Nigeria",
    name: "First bank of nigeria",
    value: "000016",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/firstbank_edtzmk.png",
  },
  {
    label: "Globus Bank",
    name: "Globus bank",
    value: "000027",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/globus_bank_s7lnyj.png",
  },
  {
    label: "Guaranty Trust Bank",
    name: "Guaranty trust bank",
    value: "000013",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/gtbank_s1exin.png",
  },
  {
    label: "Heritage Bank",
    name: "Heritage bank",
    value: "000020",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/heritage_bank_okc5jk.png",
  },
  {
    label: "Jaiz Bank",
    name: "Jaiz bank",
    value: "000006",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/jaiz_bank_azpxpv.jpg",
  },
  {
    label: "Keystone Bank",
    name: "Keystone bank",
    value: "000002",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/keystone_xekrxb.png",
  },
  {
    label: "Kuda",
    name: "Kuda",
    value: "090267",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/kudabank_zfrwks.png",
  },
  {
    label: "Moniepoint Microfinance Bank",
    name: "Moniepoint microfinance bank",
    value: "090405",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1706363918/LINK/Bank_List/var-1_utohuh.svg",
  },
  {
    label: "OPAY",
    name: "Opay",
    value: "100004",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080001/LINK/Bank_List/opay_yp9g6x.png",
  },
  {
    label: "Paga",
    name: "Paga",
    value: "100002",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/pagabank_jnkrck.png",
  },
  {
    label: "PalmPay",
    name: "Palmpay",
    value: "100033",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/palmpaybank_ayyotp.png",
  },

  {
    label: "Parallex Bank",
    name: "Parallex bank",
    value: "000030",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080329/LINK/Bank_List/parallex_uhjga5.png",
  },
  {
    label: "Polaris Bank",
    name: "Polaris bank",
    value: "000008",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080329/LINK/Bank_List/polaris_sxds2t.png",
  },
  {
    label: "Providus Bank",
    name: "Providus bank",
    value: "000023",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/providusbank_rgh1wt.png",
  },
  {
    label: "Stanbic IBTC Bank",
    name: "Stanbic ibtc bank",
    value: "000012",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/stanbicbank_kujbym.png",
  },
  {
    label: "Standard Chartered Bank",
    name: "Standard chartered bank",
    value: "000021",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/standardbank_jgmmgj.png",
  },
  {
    label: "Sterling Bank",
    name: "Sterling bank",
    value: "000001",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/sterlingbank_f8o7xw.png",
  },
  {
    label: "Suntrust Bank",
    name: "Suntrust bank",
    value: "000022",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080330/LINK/Bank_List/suntrust_vfl4zn.png",
  },
  {
    label: "TITAN TRUST BANK",
    name: "Titan trust bank",
    value: "000025",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1703080329/LINK/Bank_List/titan_o7drak.png",
  },
  {
    label: "Union Bank of Nigeria",
    name: "Union bank of nigeria",
    value: "000018",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/unionbank_os7lys.png",
  },
  {
    label: "United Bank for Africa",
    name: "United bank for africa",
    value: "000004",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/ubabank_kgvyvn.png",
  },
  {
    label: "Unity Bank",
    name: "Unity bank",
    value: "000011",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/unitybank_t9ystt.png",
  },
  {
    label: "VFD Microfinance Bank",
    name: "VFD microfinance bank",
    value: "090110",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/vfdbank_qu8vt0.png",
  },
  {
    label: "Wema Bank",
    name: "Wema bank",
    value: "000017",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/wemabank_wj9npl.png",
  },
  {
    label: "Zenith Bank",
    name: "Zenith bank",
    value: "000015",
    image:
      "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/zenithbank_qx59ic.png",
  },
];

// List of demo bani  bank
// export const bankList = [
//   {
//     value: "044",
//     label: "Access Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/accessbank_w23utv.png",
//   },
//   {
//     value: "090144",
//     label: "CIT Microfinance Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/citbank_ey1a4x.png",
//   },
//   {
//     value: "023",
//     label: "Citibank Nigeria",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/Citibank_zozj7m.png",
//   },
//   {
//     value: "063",
//     label: "Diamond Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/diamondbank_abfj7r.png",
//   },
//   {
//     value: "070",
//     label: "Fidelity Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/fidelitybank_now8zu.png",
//   },
//   {
//     value: "011",
//     label: "First Bank of Nigeria",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/firstbank_edtzmk.png",
//   },
//   {
//     value: "058",
//     label: "Guaranty Trust Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/gtbank_s1exin.png",
//   },
//   {
//     value: "090267",
//     label: "Kuda",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/kudabank_zfrwks.png",
//   },
//   {
//     value: "327",
//     label: "Paga",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/pagabank_jnkrck.png",
//   },
//   {
//     value: "100033",
//     label: "Palmpay",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/palmpaybank_ayyotp.png",
//   },
//   {
//     value: "101",
//     label: "Providus Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739938/LINK/Bank_List/providusbank_rgh1wt.png",
//   },
//   {
//     value: "221",
//     label: "Stanbic IBTC Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/stanbicbank_kujbym.png",
//   },
//   {
//     value: "068",
//     label: "Standard Chartered Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/standardbank_jgmmgj.png",
//   },
//   {
//     value: "232",
//     label: "Sterling Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/sterlingbank_f8o7xw.png",
//   },
//   {
//     value: "032",
//     label: "Union Bank of Nigeria",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/unionbank_os7lys.png",
//   },
//   {
//     value: "033",
//     label: "United Bank for Africa",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/ubabank_kgvyvn.png",
//   },
//   {
//     value: "215",
//     label: "Unity Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/unitybank_t9ystt.png",
//   },
//   {
//     value: "090110",
//     label: "VFD Microfinance Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/vfdbank_qu8vt0.png",
//   },
//   {
//     value: "035",
//     label: "Wema Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/wemabank_wj9npl.png",
//   },
//   {
//     value: "057",
//     label: "Zenith Bank",
//     image:
//       "https://res.cloudinary.com/dqw0lwkil/image/upload/v1675739939/LINK/Bank_List/zenithbank_qx59ic.png",
//   },
// ];
