export const linkBankList = [
  {
    accountName: "PASSPOINT (Okemdinachi Nwaeji)",
    accountNumber: "9636369057",
    bankName: "Providus Bank",
  },
  {
    accountName: "PASSPOINT (christopher okoye)",
    accountNumber: "9631525849",
    bankName: "Providus Bank",
  },
  {
    accountName: "PASSPOINT (marvel ekpo)",
    accountNumber: " 9628442937",
    bankName: "Providus Bank",
  },
  {
    accountName: "PASSPOINT (divine-will ekpo)",
    accountNumber: "9637681398",
    bankName: "Providus Bank",
  },
  {
    accountName: "PASSPOINT (emmanuel evarist)",
    accountNumber: "9618182180",
    bankName: "Providus Bank",
  },
  {
    accountName: "PASSPOINT (victor onyezia)",
    accountNumber: "9611298145",
    bankName: "Providus Bank",
  },
];
